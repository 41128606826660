<!--
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-10-29 17:21:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\view\index\index.vue
-->
<template>
  <div class="container">
    <s-header />
    <div class="main">
      <div class="leftBox">
        <div
          class="itemBox"
          v-for="(item, index) in menuList"
          :key="index"
          @click="menuCk(index)"
        >
          <div :class="{ active: index == menuIndex }">
            <!-- <img :src="item.icon" /> -->
            <img
              src="../../static/images/mingtai/user.png"
              v-show="index == 0"
              class="menuIcon"
            />
            <img
              src="../../static/images/mingtai/lv.png"
              v-show="index == 1"
              class="menuIcon"
            />
            <img
              src="../../static/images/mingtai/czye.png"
              v-show="index == 2"
              class="menuIcon"
            />
            <img
              src="../../static/images/mingtai/lxwm.png"
              v-show="index == 3"
              class="menuIcon"
            />
            <img
              src="../../static/images/mingtai/xgmm.png"
              v-show="index == 4"
              class="menuIcon"
            />
            <img
              src="../../static/images/mingtai/xfjl.png"
              v-show="index == 5"
              class="menuIcon"
            />
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="rightBox">
        <!-- <div
          v-for="(item, index) in menuList"
          :key="index"
          v-show="menuIndex == index"
        >
          {{ itemCon }}{{ index }}
        </div> -->
        <div class="userInfoBox" v-show="menuIndex == 0">
          <div class="ritemBox">
            <div class="rtitle">个人中心</div>
            <div class="infoBox">
              <div class="avtar">
                <img src="../../static/images/mingtai/userAvatr.png" />
              </div>
              <div class="info">
                <div class="labelItem">
                  <label class="labelTxt">Uid</label>
                  <span class="spanTxt">{{ userInfo.id }}</span>
                </div>
                <div class="labelItem">
                  <label class="labelTxt">Lv</label>
                  <span class="spanTxt">{{ userInfo.cardType?userInfo.cardType:'无' }}</span>
                </div>
                <div class="labelItem">
                  <label class="labelTxt">次数</label>
                  <span class="spanTxt">{{ userInfo.howMany?userInfo.howMany:'0' }}</span>
                </div>
                <div class="labelItem">
                  <label class="labelTxt">余额</label>
                  <span class="spanTxt">{{ userInfo.money/100 }}元</span>
                </div>
                <div class="labelItem noborder">
                  <label class="labelTxt">加入时间</label>
                  <span class="spanTxt">{{ userInfo.createTime }}</span>
                </div>
                <div class="labelItem noborder">
                  <label class="labelTxt">上次登录</label>
                  <span class="spanTxt">{{ userInfo.lastLoginTime }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="ritemBox">
            <div class="rtitle">统计数据</div>
            <div class="dataBox">
              <div class="dataItem">
                <div class="dataLabel">今日浏览</div>
                <div class="dataVal">{{ userInfo.dayHowMany }}</div>
                <div class="dataTip">您今日浏览次数</div>
              </div>
              <div class="dataItem">
                <div class="dataLabel">今日支付</div>
                <div class="dataVal">￥{{ userInfo.dayMoney/100 }}</div>
                <div class="dataTip">您今日支付金额</div>
              </div>
              <div class="dataItem">
                <div class="dataLabel">月浏览量</div>
                <div class="dataVal">{{ userInfo.monthHowMany }}</div>
                <div class="dataTip">您的月浏览次数</div>
              </div>
            </div>
          </div>
        </div>

        <div class="userInfoBox" v-show="menuIndex == 1">
          <div class="ritemBox shopAll">
            <div class="shopTop" v-if="levelData && levelData.cardInfo">
              <div class="rtitle">会员等级</div>
              <div class="cardBox">
                <div :style="'background-image:url('+cardType[levelData.cardInfo.cardType][1]+')'" class="current-vip" :class="levelData.cardInfo.status!=0?'gray-img':''">
                  <div class="triangle" :class="levelData.cardInfo.status==0?'normal-status':(levelData.cardInfo.status==1?'used-status':'pass-status')"></div>
                  <div class="cardInfoBox">
                    <div class="topInfo">
                      <img src="../../static/images/mingtai/userAvatr.png" />
                      <div class="rightUser">
                        <div class="userName">
                          {{ userInfo.name }}
                          <span class="valid_day">(有效期：{{levelData.cardInfo.residueDays}}天)</span>
                        </div>
                        <div class="current-vip-title" :class="cardType[levelData.cardInfo.cardType][3]"><img :src="cardType[levelData.cardInfo.cardType][2]">{{ cardType[levelData.cardInfo.cardType][0] }}</div>
                      </div>
                    </div>
                    <div class="botInfo">
                      <a-progress class="percent" :percent="levelData.cardInfo.howMany" strokeColor="#063A61" :showInfo="false"/>
                      <div>剩余次数:{{levelData.cardInfo.howMany}}/{{levelData.cardInfo.totalNum}}</div>
                      <a-row type="flex" justify="space-between">
                        <a-col :span="8">
                          <div class="levelTip" @click="levelRules">等级规则</div>
                        </a-col>
                        <a-col :span="8">
                          <div class="levelTip extend" @click="buyCard(levelData.cardInfo.cardType)">续费</div>
                        </a-col>
                      </a-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="shopBot">
              <div class="rtitle">
                购买会员
                <span class="littleTip">购买会员省钱更划算</span>
              </div>
              <div class="shopBox">
                <div class="shopItem" :class="cardType[item.id][4]" v-for="(item,index) in activeCardType" :key="index" @click="buyCard(item.id)">
                  <div class="vip_title" :class="cardType[item.id][3]"><img :src="cardType[item.id][2]">{{cardType[item.id][0]}}</div>
                  <div>
                    <span class="moneyPic">{{item.sort/100}}</span>
                    <span class="fontBold">元</span>
                  </div>
                  <div>(限{{item.code}}次)</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="userInfoBox" v-show="menuIndex == 2">
          <div class="ritemBox">
            <div class="rtitle">充值余额</div>
            <div class="chongBox">
              <div class="inerDiv moenyBox">
                <label>账户余额</label>
                <span>{{ userInfo.money/100 }}</span>
                元
              </div>
              <div class="inerDiv wxPay btnBox" @click="charge(0)">微信充值</div>
              <div class="inerDiv zfbPay btnBox" @click="charge(1)">支付宝充值</div>
            </div>
          </div>
        </div>

        <div class="userInfoBox" v-show="menuIndex == 3">
          <div class="ritemBox">
            <div class="rtitle">
              联系我们
              <button class="" @click="subLxwm">提交问题</button>
            </div>
            <div class="gridBox">
              <div class="header">
                <div class="hdiv">提交时间</div>
                <div class="hdiv">问题描述</div>
                <div class="hdiv">状态</div>
                <div class="hdiv">操作</div>
              </div>
              <div class="listBox">
                <div class="row" v-for="(item, index) in lxwmData" :key="index">
                  <div>
                    {{ item.createTime }}
                  </div>
                  <div>
                    {{ item.context }}
                  </div>
                  <div>
                    {{ lxwmState(item.status) }}
                  </div>
                  <div class="editCz">
                    <a @click="lxwmLook(item)">查看</a>
                    <a @click="lxwmDel(item)">删除</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="footetPage">
              <a @click="start" :disabled="current==1">首页</a>
              <a @click="prev" :disabled="current<2">上一页</a>
              <a @click="next" :disabled="current>=totalPage">下一页</a>
              <a @click="end" :disabled="current>=totalPage">尾页</a>
              <span>共{{ total }}条</span>
            </div>
          </div>
        </div>

        <div class="userInfoBox" v-show="menuIndex == 4">
          <div class="ritemBox">
            <div class="rtitle">修改密码</div>
            <div class="updBox">
              <a-form :form="form" @submit="updPsd">
                <a-form-item
                  label="原密码"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  has-feedback
                >
                  <a-input size="large" placeholder="请输入原密码"  type="password" v-decorator="['password', {rules: [{required: true,  message: '请输入原密码！'}]}]" />
                </a-form-item>
                <a-form-item
                  label="新密码"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  has-feedback
                >
                  <a-input size="large" placeholder="请输入新密码" type="password" v-decorator="['newPassword', {rules: [{required: true,  message: '请输入新密码(至少8位且包含数字，大小写字母)！',pattern:/^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,}$/,len:8},{
                        validator: validateToNextPassword,
                      },]}]" />
                </a-form-item>
                <a-form-item
                  label="重复新密码"
                  :labelCol="labelCol"
                  :wrapperCol="wrapperCol"
                  has-feedback
                >
                  <a-input size="large" placeholder="请再次输入新密码" type="password" v-decorator="['confirm', {rules: [{required: true,  message: '请再次输入新密码(至少8位且包含数字，大小写字母)！',pattern:/^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,}$/,len:8},
                      {
                        validator: compareToFirstPassword,
                      }]}]" />
                </a-form-item>
                <a-form-item>
                  <a-button type="primary" size="large" html-type="submit">
                    确认
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>

        <div class="userInfoBox" v-show="menuIndex == 5">
          <div class="ritemBox">
            <div class="rtitle">消费记录</div>
            <div class="gridBox">
              <div class="header">
                <div class="hdiv">交易时间</div>
                <div class="hdiv">付款方式</div>
                <div class="hdiv">消费金额</div>
                <div class="hdiv">账户余额</div>
                <div class="hdiv">消费商品</div>
              </div>
              <div class="listBox">
                <div class="row" v-for="(item, index) in xfjlData" :key="index">
                  <div>
                    {{ item.successTime }}
                  </div>
                  <div>
                    {{ item.moneyTypeName }}
                  </div>
                  <div>
                    {{ item.money }}
                  </div>
                  <div>
                    {{ item.walletMoney }}
                  </div>
                  <div class="consume">
                    <router-link v-if="item.showId" :to="{name:'Aticle',query:{id:item.showId}}">{{ item.consumables }}</router-link>
                    <span v-else>{{ item.consumables }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="footetPage">
              <a @click="start" :disabled="current==1">首页</a>
              <a @click="prev" :disabled="current<2">上一页</a>
              <a @click="next" :disabled="current>=totalPage">下一页</a>
              <a @click="end" :disabled="current>=totalPage">尾页</a>
              <span>共{{ total }}条</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      title="提交问题"
      :visible="subVis"
      :confirm-loading="confirmLoading"
      @cancel="handleLxwm"
      :footer="null"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        @submit="handleSubmit"
      >
        <!-- <a-form-item label="问题类型">
          <span v-if="lxwmItem"></span>
          <a-select v-else placeholder="问题类型">
            <a-select-option value="male"> male </a-select-option>
            <a-select-option value="female"> female </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="问题描述">
          <span v-if="lxwmItem">{{ lxwmItem.context }}</span>
          <a-textarea v-else placeholder="问题描述" :rows="4" style="resize: none" v-decorator="['context']"/>
        </a-form-item>
        <a-form-item v-if="lxwmItem" label="平台回复">
          <span>{{ lxwmItem.statusContext }}</span>
        </a-form-item>
        <!-- <a-form-item label="问题备注">
          <span v-if="lxwmItem"></span>
          <a-input v-else placeholder="问题备注" />
        </a-form-item> -->

        <a-form-item v-else :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" html-type="submit"> 确认提交 </a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      title="充值金额"
      :visible="chargeVis"
      @cancel="handleChargeCancel"
      :footer="null"
    >
      <a-input-search placeholder="请输入自定义金额" :suffix="rewardValue?'送'+rewardValue:''" size="large" @change="inputChange" :value="chargeValue" @search="toPay">
        <a-button slot="enterButton">
          去充值
      </a-button>
      </a-input-search>
      <a-radio-group size="large" style="margin-top:10px;" :value="checkedRadio" @change="radioChange">
        <a-radio-button :value="item" v-for="(item,index) in chargeRewardList" :key="index">
          充{{item.split(':')[0]}}送{{item.split(':')[1]}}
        </a-radio-button>
      </a-radio-group>
    </a-modal>
    <pay ref="pay"/>
    <s-footer />
  </div>
</template>

<script>
import sHeader from "@/view/components/Header";
import sFooter from "@/view/components/Footer";
import pay from "@/view/components/pay";
import vip1_path from "../../static/images/mingtai/vip1.png";
import vip2_path from "../../static/images/mingtai/vip2.png";
import vip3_path from "../../static/images/mingtai/vip3.png";
import vip4_path from "../../static/images/mingtai/vip4.png";
import v1_path from "../../static/images/mingtai/v1.png";
import v2_path from "../../static/images/mingtai/v2.png";
import v3_path from "../../static/images/mingtai/v3.png";
import v4_path from "../../static/images/mingtai/v4.png";

export default {
  components: {
    sHeader,
    sFooter,
    pay,
  },
  data() {
    return {
      vip_path: vip1_path,
      subVis: false,
      chargeVis: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 }
      },
      form: this.$form.createForm(this),
      form2: this.$form.createForm(this),
      menuList: [
        {
          title: "个人中心",
          icon: "../../static/images/mingtai/user.png",
        },
        {
          title: "会员等级",
          icon: "",
        },
        {
          title: "充值余额",
          icon: "",
        },
        {
          title: "联系我们",
          icon: "",
        },
        {
          title: "修改密码",
          icon: "",
        },
        {
          title: "消费记录",
          icon: "",
        },
      ],
      menuIndex: 0,
      userInfo: {},
      lxwmData: [
        {
          id: "222xxx1s",
          time: "2021-05-12 20:23:03",
          content: "充值失败",
          state: 1,
        },
      ],
      xfjlData: [
        {
          id: "222xxx1s",
          time: "2021-05-12 20:23:03",
          type: "0",
          money: 6,
          sum: 100,
          shop: "人体",
          shopid: "", //关联的id
        },
      ],
      confirmLoading: false,
      current: 1, //当前页
      pageSize: 10, //一页多少条
      total: 0, //总条数
      totalPage: 1, //总共页数
      lxwmItem: null,//查看问题详情
      cardType:{'1410162849813344261':['月度会员',vip1_path,v1_path,'vip_title1','monthCard'],'1410162849813344260':['季度会员',vip2_path,v2_path,'vip_title2','quarterCard'],'1410162849813344259':['年度会员',vip3_path,v3_path,'vip_title3','yearCard'],'1410162849813344271':['永久会员',vip4_path,v4_path,'vip_title4','allCard']},
      levelData:null,
      activeCardType:null,
      chargeRewardList:[],
      checkedRadio:'',
      chargeValue:null,
      rewardValue:null,
      payWay:0,//0微信1支付宝
    };
  },
  created(e) {
    if(this.$route.query && this.$route.query.index){
      this.menuIndex = parseInt(this.$route.query.index)
    }
    this.getData()
  },
  mounted() {},
  methods: {
    //购买会员卡
    buyCard(cardId){
      let payMoney = 0
      let currentArr = this.levelData.cardType.filter(item => {return item.id==cardId})
      if(currentArr.length>0){
        payMoney = currentArr[0].sort
      }
      let data = {payMoney:parseInt(payMoney),payType:5,bizId:cardId}
      this.$refs.pay.showPay(data,this.getData)
    },
    inputChange(e){
      let inputValue = e.target.value
      this.chargeValue = inputValue
      let rewardValue = null
      for (var i=0;i<this.chargeRewardList.length;i++) {
        const item = this.chargeRewardList[i]
        if(item.split(':')[0]==inputValue){
          rewardValue = item.split(':')[1]
          break
        }else{
          continue
        }
      }
      this.rewardValue =rewardValue
    },
    radioChange(e){
      this.checkedRadio = e.target.value
      this.chargeValue = this.checkedRadio.split(':')[0]
      this.rewardValue = this.checkedRadio.split(':')[1]
    },
    charge(payWay){
      this.payWay = payWay
      this.$api.configDetail({code:'charge_reward'}).then(res => {
        if(res.success){
          this.chargeRewardList = res.data.rows[0].value.split(',')
          this.chargeVis = true
        }
      })
    },
    toPay(){
      this.$refs.pay.showPay({payMoney:parseFloat(this.chargeValue)*100,payType:3,payWay:this.payWay},this.getData)
      this.handleChargeCancel()
    },
    handleChargeCancel(){
      this.chargeVis = false
      this.chargeRewardList = []
      this.checkedRadio = ''
      this.chargeValue = null
      this.rewardValue = null
    },
    levelRules() {
      this.$info({
        title: '等级规则',
        okText:'知道了',
        content: (
          <div style="font-size:14px;color: #828282;">
            <p>1、有效期：除永久会员外，其余会员卡均有有效期，月度会员30天，季度会员90天，年度会员365天；</p>
            <p>2、使用次数：除永久会员外，其余会员卡均有有限使用次数，具体次数见下列卡片；</p>
            <p>3、有效期剩余0天则卡片作废，同理，使用次数剩余0次卡片也作废；</p>
          </div>
        ),
      });
    },
    handleSubmit(){
      const { form2: { validateFields } } = this
      validateFields((errors, values) => {
        if(!errors){
          this.$api.feedbackAdd({},values).then((res) =>{
            if(res.success){
              this.$message.success('添加成功')
              this.handleLxwm()
              this.getData()
            }else{
              this.$message.error('添加失败：'+res.message)
            }
          })

        }
      })
    },
    //提交问题弹框
    subLxwm() {
      // modal..
      this.subVis = true;
    },
    handleLxwm() {
      this.lxwmItem = null
      this.subVis = false;
    },
    menuCk(index) {
      this.current = 1
      this.pageSize = 10
      this.total = 0
      this.totalPage = 1
      this.menuIndex = index;
      this.getData()
    },
    getData(){
      switch(this.menuIndex){
        case 0:
          this.$api.getInfo().then(res => {
            this.userInfo = res.data
          })
          break;
        case 1:
          this.$api.getLevelInfo().then(res => {
            const self = this
            this.levelData = res.data
            if(res.data.cardInfo){
              this.activeCardType = this.levelData.cardType.filter(function(item){
                return item.id != self.levelData.cardInfo.cardType
              })
            }else{
              this.activeCardType = res.data.cardType
            }
          })
          break;
        case 2:
           this.$api.getInfo().then(res => {
            this.userInfo = res.data
          })
          break;
        case 3:
          this.$api.feedbackPageList({pageNo:this.current,pageSize:this.pageSize},{}).then(res => {
            this.lxwmData = res.data.rows
            this.total = res.data.totalRows
            this.totalPage = res.data.totalPage
          })
          break;
        case 4:
          // api = this.$getInfo
          break;
        case 5:
          this.$api.consumPageList({pageNo:this.current,pageSize:this.pageSize},{}).then(res => {
            this.xfjlData = res.data.rows
            this.total = res.data.totalRows
            this.totalPage = res.data.totalPage
          })
          break;
        default:
          break;
      }
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('newPassword')) {
        callback('请确认两次输入密码的一致性！');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    },
    //修改密码
    updPsd() {
      const { form: { validateFields } } = this
      validateFields((errors, values) => {
        if(!errors){
          this.$confirm({
            title: '提示',
            content: '确定要修改密码吗 ?(下次登录生效)',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              this.$api.updatePwd({},values).then((res) =>{
                if(res.success){
                  this.$message.success('修改成功')
                }else{
                  this.$message.error('修改失败：'+res.message)
                }
              })
            },
            onCancel () {
            }
          })
        }
      })
    },
    start() {
      this.current = 1;
      this.getData();
    },
    prev() {
      if(this.current>1){
        this.current -= 1
        this.getData()
      }
    },
    next() {
      if (this.current < this.totalPage) {
        this.current += 1;
        this.getData();
      }
    },
    end() {
      this.current = this.totalPage;
      this.getData();
    },
    //联系我们列表状态
    lxwmState(val) {
      let state = ["未处理", "已处理"];
      return state[val];
    },
    //联系我们查看
    lxwmLook(row) {
      this.lxwmItem = row
      this.subVis = true;
    },
    //联系我们删除
    lxwmDel(row) {
      this.$confirm({
        title: '提示',
        content: '确定要删除该条记录吗 ?',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$api.feedbackDelete({},{id:row.id}).then(res => {
            if(res.success){
              this.$message.success('删除成功')
              this.getData()
            }
          })
        },
        onCancel () {
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
// 公共样式
.hide {
  display: none;
}
.show {
  display: block;
}
a {
  color: #656565;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25)
}
* {
  font-family: "微软雅黑";
}
.consume {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.inerDiv {
  display: inline-block;
  vertical-align: top;
}
.vip_title {
  width: 50%;
  border-radius: 15px;
  margin: auto;
  background-color: white;
  font-weight: bolder;
}
.vip_title1 {
  color: #e2be86;
}
.vip_title2 {
  color: #e09e28;
}
.vip_title3 {
  color: #e5852e;
}
.vip_title4 {
  color: #c6221e;
}
.gray-img {
  filter: grayscale(100%);
}
.current-vip {
  background-size: 100%;
  height: 100%;
  background-repeat: no-repeat;
  width: 45%;
  position: relative;
  overflow: hidden;
}
.normal-status {
  background-image:url('../../static/images/mingtai/current_vip.png');
}
.pass-status {
  background-image:url('../../static/images/mingtai/pass.png');
}
.used-status {
  background-image:url('../../static/images/mingtai/used.png');
}
.valid_day {
  font-size: 12px;
  color: #f0f0f0;
  font-weight: lighter;
}
.triangle {
  position: absolute;
  right: 0;
  background-size: 100%;
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
}
.current-vip-title {
  width: 55%;
  padding: 1px 10px 1px 10px;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bolder;
  img {
    width: 20px !important;
    height: unset !important;
    margin-right: 3%;
  }
}
.ant-btn-primary {
  width: 41.5%;
  text-align: center;
  margin-left: 16.5%;
  margin-top: 2%;
  color: #fff;
  background-color: #063a61;
  border-color: #063a61;
}
.ant-form-item-required {
  color: #063a61 !important;
  font-size: large !important;
}
.footetPage {
  width: 100%;
  text-align: center;
  padding: 15px;
  a {
    font-size: 14px;
    padding: 0px 5px;
    border: 1px solid #cecece;
    margin: 0px 5px;
  }
  span {
    font-size: 14px; 
    color: #656565;
  }
  a:hover {
    color: #063A61;
  }
}
.container {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .main {
    width: 1366px;
    margin: 0 auto;
    height: calc(100% - 122px);
    box-sizing: border-box;
    padding: 20px 0px;
    display: flex;
    column-gap: 30px;

    .leftBox {
      flex: 1;
      background-color: #ffffff;
      box-sizing: border-box;
      padding-top: 50px;
      .itemBox {
        div {
          font-size: 18px;
          color: #063a61;
          box-sizing: border-box;
          cursor: pointer;
          padding-left: 30px;
          border-left: 3px solid transparent;
          margin-bottom: 25px;
        }
        .active {
          border-left: 3px solid #063a61;
        }
        div:hover {
          border-left: 3px solid #063a61;
        }
        .menuIcon {
          width: 26px;
          height: 26px;
        }
      }
    }
    .rightBox {
      flex: 3;
      background-color: #ffffff;
      box-sizing: border-box;
      padding: 40px 60px;

      .userInfoBox {
        height: 100%;
        display: flex;
        flex-direction: column;
        .ritemBox {
          flex: 2;
        }
      }
      .shopAll {
        display: flex;
        flex-direction: column;
        .shopTop {
          flex: 50%;
          .cardBox {
            height: calc(100% - 36px);
            box-sizing: border-box;
            position: relative;
            img {
              width: 350px;
              height: 200px;
            }
            .cardInfoBox {
              position: absolute;
              width: 350px;
              height: 200px;
              top: 0px;
              box-sizing: border-box;
              padding: 20px;
              display: flex;
              flex-direction: column;
              .topInfo {
                display: flex;
                align-items: center;
                width: 100%;
                img {
                  width: 80px;
                  height: 80px;
                }
                .rightUser {
                  width: 100%;
                  margin-left: 20px;
                  .userName {
                    padding-left: 10px;
                    padding-bottom: 5px;
                    font-size: 16px;
                    font-weight: bolder;
                  }
                }
              }
              .botInfo {
                margin-top:10px;
                display: flex;
                flex-direction: column;
                width:100%;
                font-size:14px;
                .percent{
                 width: 70%;
                }
                .levelTip {
                  color: white;
                  background-color: #063a61;
                  border-radius: 5px;
                  padding: 5px;
                  width:80px;
                  text-align: center;
                  cursor: pointer;
                  margin-top:5px;
                }
                .extend {
                  background-color: #7b7b7b;
                }
              }
            }
          }
        }
        .shopBot {
          flex: 50%;
          .shopBox {
            display: flex;
            /* 子元素溢出父容器时换行 */
            flex-flow: row wrap;
            gap: 20px;
            .monthCard {
              background-image: url("../../static/images/mingtai/vip1.png");
              background-size: 100% 100%;
              width: 290px;
              cursor: pointer;
            }
            .quarterCard {
              background-image: url("../../static/images/mingtai/vip2.png");
              background-size: 100% 100%;
              width: 290px;
              cursor: pointer;
            }
            .yearCard {
              background-image: url("../../static/images/mingtai/vip3.png");
              background-size: 100% 100%;
              width: 290px;
              cursor: pointer;
            }
            .allCard {
              background-image: url("../../static/images/mingtai/vip4.png");
              background-size: 100% 100%;
              width: 290px;
              cursor: pointer;
            }
            .shopItem {
              cursor: pointer;
              color: white;
              box-sizing: border-box;
              padding: 20px;
              text-align: center;
              font-size: 16px;
              img{
                width: 15%;
                margin-right: 5%;
              }
              .moneyPic {
                font-size: 48px;
                color: white;
                font-weight: bold;
              }
              .fontBold {
                font-weight: bold;
              }
            }
          }
        }
      }

      .ritemBox {
        .rtitle {
          font-size: 24px;
          color: #666666;
          height: 45px;
          .littleTip {
            font-size: 16px;
            color: #06375d;
            font-weight: bold;
          }
          button {
            background-color: #063a61;
            color: white;
            border: 0px;
            font-size: 16px;
            padding: 5px 15px;
            float: right;
            cursor: pointer;
          }
          button:hover {
          }
        }
        .infoBox {
          margin-top: 30px;
          display: flex;
          column-gap: 30px;
          .avtar {
            flex: 1;
            img {
              width: 150px;
            }
          }
          .info {
            flex: 3;
            display: flex;
            flex-wrap: wrap;

            .labelItem {
              flex: 50%;
              max-width: 50%;
              border-bottom: 1px solid #9b9b9b;
              align-items: center;
              display: flex;

              .labelTxt {
                color: #676767;
                font-size: 18px;
                // font-weight: 600;
                width: 100px;
              }
              .spanTxt {
                color: #23456c;
                font-size: 18px;
                flex: 1;
              }
            }
            .noborder {
              border: 0px;
            }
          }
        }
        .dataBox {
          display: flex;
          text-align: center;
          justify-content: center;
          align-content: center;
          height: 100%;
          .dataItem {
            flex: 33%;
            display: flex;
            text-align: center;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            .dataLabel {
              font-size: 22px;
              color: #6c6c6c;
            }
            .dataVal {
              font-size: 48px;
              color: #063a61;
              font-weight: bold;
            }
            .dataTip {
              color: #b6b6b6;
              font-size: 16px;
            }
          }
        }
        .gridBox {
          margin-top: 30px;
          .header {
            background-color: #f2f2f2;
            display: flex;
            padding: 10px 0px;
            .hdiv {
              flex: 25%;
              font-size: 16px;
              color: #a4a4a4;
              text-align: center;
            }
          }
          .listBox {
            width: 100%;
            .row {
              width: 100%;
              display: flex;
              div {
                flex: 25%;
                font-size: 16px;
                text-align: center;
                color: #909090;
                box-sizing: border-box;
                padding: 10px 5px;
                border-bottom: 1px solid #a2a2a2;
              }
              .editCz {
                a {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }

      .chongBox {
        margin-top: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        .moenyBox {
          color: #063a61;
          font-size: 14px;
          label {
            font-size: 14px;
          }
          span {
            font-size: 24px;
            font-weight: bold;
            margin-left: 3px;
            margin-right: 3px;
          }
        }
        .btnBox {
          margin-left: 30px;
          background-repeat: no-repeat;
          border: 1px solid #eaeaea;
          padding: 10px 30px;
          text-align: center;
          font-size: 14px;
          color: #888888;
          background-position: 10% 50%;
          padding-left: 40px;
          padding-right: 10px;
          cursor: pointer;
          background-size: 24px;
        }
        .btnBox:hover {
          color: #063a61;
        }
        .wxPay {
          background-image: url("../../static/images/mingtai/wxpay.png");
        }
        .zfbPay {
          background-image: url("../../static/images/mingtai/zfbpay.png");
        }
      }
      .updBox {
        margin-top: 50px;
        box-sizing: border-box;
        .rotItem {
          margin-bottom: 15px;
          label {
            width: 100px;
            color: #a9a9a9;
            font-size: 16px;
            display: inline-block;
            text-align: right;
            margin-right: 10px;
          }
          input {
            border: 1px solid #f2f2f2;
            width: 350px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #a9a9a9;
            padding-left: 10px;
          }
          button {
            width: 350px;
            height: 40px;
            line-height: 40px;
            background-color: #063a61;
            border: 0px;
            font-size: 16px;
            cursor: pointer;
            margin-left: 110px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>